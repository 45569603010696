<template>
    <div class="Commission">
        <div class="Commission-wrap">
            <ul class="Commission-wrap-tab">
                <li @click="cur=0" :class="{selected:cur==0}">全部</li>
                <li @click="cur=1" :class="{selected:cur==1}">直属达人</li>
                <li @click="cur=2" :class="{selected:cur==2}">间接达人</li>
                <li @click="cur=3" :class="{selected:cur==3}">自购饭佣</li>
            </ul>
            <div class="Commission-wrap-totle">
                <p>全部(4)</p>
                <p>待入账(4)</p>
                <p>已到账(4)</p>
                <p>有冻结(4)</p>
                <p>有扣减(4)</p>
            </div>
            <div class="Commission-wrap-tab2" v-show="cur==0">
                <div class="myteam-wrap-top-2">
                    <div class="myteam-wrap-top-2-list">
                        <div class="myteam-wrap-top-2-list-top">
                        <div class="myteam-wrap-top-2-list-top-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                            <p>自购订单</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-top-r">
                            <p>已到账</p>
                        </div>
                        </div>
                        <div class="myteam-wrap-top-2-list-bottom">
                        <div class="myteam-wrap-top-2-list-bottom-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                        </div>
                        <p class="myteam-wrap-top-2-list-bottom-c">代餐豆干无糖精无油无添加减 低卡脂小零食健身解馋热...</p>
                        <div class="myteam-wrap-top-2-list-bottom-r">
                            <p>￥45.50</p>
                            <p>x1</p>
                        </div>
                        </div>

                        <div class="myteam-wrap-top-2-list-footer">
                        <p v-on:click="changeFoldState">
                            {{brandFold?'展开':'收起'}}
                            <svg
                            t="1598498850083"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2828"
                            width="14"
                            height="8"
                            >
                            <path
                                d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415z"
                                p-id="2829"
                                fill="#adadad"
                            />
                            <path
                                d="M947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
                                p-id="2830"
                                fill="#adadad"
                            />
                            </svg>
                        </p>
                        </div>
                        <!-- //下拉 -->
                        <div class="myteam-wrap-top-2-list-down" v-show="boxshow">
                        <div class="myteam-wrap-top-2-list-down-1">
                            <p>购买人:李**</p>
                            <p>
                            实付:
                            <span>￥45.50</span>
                            </p>
                        </div>
                        <p>下单时间:2020-08-25 09:38:25</p>
                        <p>订单编号:465312486513</p>
                        <p>收入详情:饭佣2.5元</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-gongxian">
                            <p>贡献:</p>
                            <p>2.5元</p>
                        </div>
                    </div>
                </div>
                <div class="myteam-wrap-top-2">
                    <div class="myteam-wrap-top-2-list">
                        <div class="myteam-wrap-top-2-list-top">
                        <div class="myteam-wrap-top-2-list-top-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                            <p>自购订单</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-top-r">
                            <p>待入账</p>
                        </div>
                        </div>
                        <div class="myteam-wrap-top-2-list-bottom">
                        <div class="myteam-wrap-top-2-list-bottom-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                        </div>
                        <p class="myteam-wrap-top-2-list-bottom-c">代餐豆干无糖精无油无添加减 低卡脂小零食健身解馋热...</p>
                        <div class="myteam-wrap-top-2-list-bottom-r">
                            <p>￥45.50</p>
                            <p>x1</p>
                        </div>
                        </div>

                        <div class="myteam-wrap-top-2-list-footer">
                        <p v-on:click="changeFoldState">
                            {{brandFold?'展开':'收起'}}
                            <svg
                            t="1598498850083"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2828"
                            width="14"
                            height="8"
                            >
                            <path
                                d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415z"
                                p-id="2829"
                                fill="#adadad"
                            />
                            <path
                                d="M947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
                                p-id="2830"
                                fill="#adadad"
                            />
                            </svg>
                        </p>
                        </div>
                        <!-- //下拉 -->
                        <div class="myteam-wrap-top-2-list-down" v-show="boxshow">
                        <div class="myteam-wrap-top-2-list-down-1">
                            <p>购买人:李**</p>
                            <p>
                            实付:
                            <span>￥45.50</span>
                            </p>
                        </div>
                        <p>下单时间:2020-08-25 09:38:25</p>
                        <p>订单编号:465312486513</p>
                        <p>收入详情:饭佣2.5元</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-gongxian">
                            <p>贡献:</p>
                            <p>2.5元</p>
                        </div>
                    </div>
                </div>
                <div class="myteam-wrap-top-2">
                    <div class="myteam-wrap-top-2-list">
                        <div class="myteam-wrap-top-2-list-top">
                        <div class="myteam-wrap-top-2-list-top-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                            <p>自购订单</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-top-r">
                            <p>冻结中</p>
                        </div>
                        </div>
                        <div class="myteam-wrap-top-2-list-bottom">
                        <div class="myteam-wrap-top-2-list-bottom-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                        </div>
                        <p class="myteam-wrap-top-2-list-bottom-c">代餐豆干无糖精无油无添加减 低卡脂小零食健身解馋热...</p>
                        <div class="myteam-wrap-top-2-list-bottom-r">
                            <p>￥45.50</p>
                            <p>x1</p>
                        </div>
                        </div>

                        <div class="myteam-wrap-top-2-list-footer">
                        <p v-on:click="changeFoldState">
                            {{brandFold?'展开':'收起'}}
                            <svg
                            t="1598498850083"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2828"
                            width="14"
                            height="8"
                            >
                            <path
                                d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415z"
                                p-id="2829"
                                fill="#adadad"
                            />
                            <path
                                d="M947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
                                p-id="2830"
                                fill="#adadad"
                            />
                            </svg>
                        </p>
                        </div>
                        <!-- //下拉 -->
                        <div class="myteam-wrap-top-2-list-down" v-show="boxshow">
                        <div class="myteam-wrap-top-2-list-down-1">
                            <p>购买人:李**</p>
                            <p>
                            实付:
                            <span>￥45.50</span>
                            </p>
                        </div>
                        <p>下单时间:2020-08-25 09:38:25</p>
                        <p>订单编号:465312486513</p>
                        <p>收入详情:饭佣2.5元</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-gongxian">
                            <p>贡献:</p>
                            <p>2.5元</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Commission-wrap-tab2" v-show="cur==1">
                <div class="myteam-wrap-top-2">
                    <div class="myteam-wrap-top-2-list">
                        <div class="myteam-wrap-top-2-list-top">
                        <div class="myteam-wrap-top-2-list-top-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                            <p>自购订单</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-top-r">
                            <p>已到账</p>
                        </div>
                        </div>
                        <div class="myteam-wrap-top-2-list-bottom">
                        <div class="myteam-wrap-top-2-list-bottom-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                        </div>
                        <p class="myteam-wrap-top-2-list-bottom-c">代餐豆干无糖精无油无添加减 低卡脂小零食健身解馋热...</p>
                        <div class="myteam-wrap-top-2-list-bottom-r">
                            <p>￥45.50</p>
                            <p>x1</p>
                        </div>
                        </div>

                        <div class="myteam-wrap-top-2-list-footer">
                        <p v-on:click="changeFoldState">
                            {{brandFold?'展开':'收起'}}
                            <svg
                            t="1598498850083"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2828"
                            width="14"
                            height="8"
                            >
                            <path
                                d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415z"
                                p-id="2829"
                                fill="#adadad"
                            />
                            <path
                                d="M947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
                                p-id="2830"
                                fill="#adadad"
                            />
                            </svg>
                        </p>
                        </div>
                        <!-- //下拉 -->
                        <div class="myteam-wrap-top-2-list-down" v-show="boxshow">
                        <div class="myteam-wrap-top-2-list-down-1">
                            <p>购买人:李**</p>
                            <p>
                            实付:
                            <span>￥45.50</span>
                            </p>
                        </div>
                        <p>下单时间:2020-08-25 09:38:25</p>
                        <p>订单编号:465312486513</p>
                        <p>收入详情:饭佣2.5元</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-gongxian">
                            <p>贡献:</p>
                            <p>2.5元</p>
                        </div>
                    </div>
                </div>
                <div class="myteam-wrap-top-2">
                    <div class="myteam-wrap-top-2-list">
                        <div class="myteam-wrap-top-2-list-top">
                        <div class="myteam-wrap-top-2-list-top-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                            <p>自购订单</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-top-r">
                            <p>待入账</p>
                        </div>
                        </div>
                        <div class="myteam-wrap-top-2-list-bottom">
                        <div class="myteam-wrap-top-2-list-bottom-l">
                            <img src="../../../assets/kaorou.jpg" alt />
                        </div>
                        <p class="myteam-wrap-top-2-list-bottom-c">代餐豆干无糖精无油无添加减 低卡脂小零食健身解馋热...</p>
                        <div class="myteam-wrap-top-2-list-bottom-r">
                            <p>￥45.50</p>
                            <p>x1</p>
                        </div>
                        </div>

                        <div class="myteam-wrap-top-2-list-footer">
                        <p v-on:click="changeFoldState">
                            {{brandFold?'展开':'收起'}}
                            <svg
                            t="1598498850083"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2828"
                            width="14"
                            height="8"
                            >
                            <path
                                d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415z"
                                p-id="2829"
                                fill="#adadad"
                            />
                            <path
                                d="M947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
                                p-id="2830"
                                fill="#adadad"
                            />
                            </svg>
                        </p>
                        </div>
                        <!-- //下拉 -->
                        <div class="myteam-wrap-top-2-list-down" v-show="boxshow">
                        <div class="myteam-wrap-top-2-list-down-1">
                            <p>购买人:李**</p>
                            <p>
                            实付:
                            <span>￥45.50</span>
                            </p>
                        </div>
                        <p>下单时间:2020-08-25 09:38:25</p>
                        <p>订单编号:465312486513</p>
                        <p>收入详情:饭佣2.5元</p>
                        </div>
                        <div class="myteam-wrap-top-2-list-gongxian">
                            <p>贡献:</p>
                            <p>2.5元</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .Commission{
        width: 100%;
        height: 100%;
        background: #f6f6f6;
        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }
        .Commission-wrap{
            width: 100%;
            .Commission-wrap-tab{
                font-size: 0.28rem;
                color: #717171;
                display: flex;
                background: #fff;
                border-bottom: 1px solid #ececec;
                li{
                    width: 25%;
                    height: 1rem;
                    line-height: 1rem;
                    text-align: center;
                }
                .selected{
                    color: #ff5400;
                    border-bottom: 1px solid #ff5400;
                }
            }
            .Commission-wrap-totle{
                width: 100%;
                height: 0.91rem;
                line-height: 0.91rem;
                display: flex;
                font-size: 0.23rem;
                background: #fff;
                p{
                    width: 25%;
                    text-align: center;
                }
            }
            .myteam-wrap-top-2 {
                width: 7.02rem;
                margin: 0 auto;
                
                .myteam-wrap-top-2-list {
                width: 100%;
                border-radius: 0.07rem;
                background: #fff;
                padding: 0.2rem;
                box-sizing: border-box;
                margin-top: 0.24rem;
                .myteam-wrap-top-2-list-top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .myteam-wrap-top-2-list-top-l {
                    display: flex;
                    align-items: center;
                    img {
                        width: 0.63rem;
                        height: 0.63rem;
                        border-radius: 50%;
                        margin-right: 0.18rem;
                    }
                    p {
                        width: 1.15rem;
                        height: 0.42rem;
                        line-height: 0.42rem;
                        border-radius: 0.07rem;
                        font-size: 0.23rem;
                        background: #ffedd8;
                        color: #ff5c03;
                        text-align: center;
                    }
                    }
                    .myteam-wrap-top-2-list-top-r {
                    p {
                        font-size: 0.22rem;
                        text-align: center;
                    }
                    }
                }
                .myteam-wrap-top-2-list-bottom {
                    margin-top: 0.46rem;
                    display: flex;
                    .myteam-wrap-top-2-list-bottom-l {
                    img {
                        width: 1.15rem;
                        height: 0.93rem;
                        display: block;
                    }
                    }
                    .myteam-wrap-top-2-list-bottom-c {
                    margin-left: 0.22rem;
                    font-size: 0.27rem;
                    line-height: 0.5rem;
                    }
                    .myteam-wrap-top-2-list-bottom-r {
                    line-height: 0.5rem;
                    p:nth-child(1) {
                        font-size: 0.23rem;
                    }
                    p:nth-child(2) {
                        font-size: 0.23rem;
                        color: #b3b3b3;
                        margin-left: 0.6rem;
                    }
                    }
                }
                .myteam-wrap-top-2-list-footer {
                    font-size: 0.23rem;
                    color: #c4c4c4;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 0.27rem;
                }
                .myteam-wrap-top-2-list-down{
                    width: 100%;
                    margin-top: 0.53rem;
                    .myteam-wrap-top-2-list-down-1{
                        font-size: 0.24rem;
                        color: #adadad;
                        display: flex;
                        justify-content: space-between;
                        p:nth-child(2){
                            span{
                                color: #000;
                            }
                        }
                    }
                    p{
                            font-size: 0.13rem;
                            color: #b8b8b8;
                    }
                }
                .myteam-wrap-top-2-list-gongxian{
                    height: 1.01rem;
                    line-height: 1.01rem;
                    display: flex;
                    justify-content: flex-end;
                    p:nth-child(1){
                        font-size: 0.22rem;
                    }
                    p:nth-child(2){
                        font-size: 0.23rem;
                        color: #ff5400;
                    }
                }
                }
            }
        }
    }
</style>
<script>
export default {
    data(){
        return{
            brandFold: true,
            boxshow:false,
            cur:0
        }
    },
    methods:{
        goback(){
            this.$router.go(-1)
        },
         changeFoldState() {
            this.brandFold = !this.brandFold,
            this.boxshow=!this.boxshow
        }
    },
    created() {
       document.title = "饭佣订单";
    },
}
</script>